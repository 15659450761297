import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import TileWMS from 'ol/source/TileWMS'
import VectorLayer from 'ol/layer/Vector';
import * as olProj from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import {Fill, RegularShape, Stroke, Style} from 'ol/style';
import WMTS from 'ol/source/WMTS.js';
import * as olExtent from 'ol/extent';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
//import {getTopLeft, getWidth} from 'ol/extent';


const merc = olProj.get('EPSG:3857')

const map = new Map({
  target: 'map',
  view: new View({
    projection: merc,
    center: olProj.transform([-9.13, 38.7], 'EPSG:4326', 'EPSG:3857'),
    zoom: 7
  })
});

const osm = new TileLayer({
    source: new OSM(),
    visible: true
});




// const stamen = new TileLayer({
//   source: new Stamen({
//     layer: 'watercolor'
//   })
// });

// const terrain = new TileLayer({
//   source: new Stamen({
//     layer: 'terrain'
//   }),
// });

const caop = new TileLayer({
  source: new TileWMS({
    url: 'http://localhost:8080/geoserver/wms',
    params: {
      'LAYERS': 'csig:caop',
      'TILED': true
    }
  })
});

const iconStyles = {
  'triangle': new Style({
    image: new RegularShape({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1.0)'
      }),
      stroke: new Stroke({
        color: 'rgba(63, 162, 50, 1.0)',
        width: 3
      }),
      points: 3,
      radius: 6,
      angle: 0,
    }),
  }),
  'square': new Style({
    image: new RegularShape({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1.0)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 101, 80, 1.0)',
        width: 3
      }),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    }),
  })
};


const meteo = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON(),
    url: function(extent){
      return ('http://localhost:8080/geoserver/wfs?service=WFS&' +
        'version=1.1.0&request=GetFeature&typename=csig:est_meteorologicas&' +
        'outputFormat=application/json&srsname=EPSG:3857&' +
        'bbox=' +
        extent.join(',') +
        ',EPSG:3857'
      );
    },
    strategy: bboxStrategy,
    projection: merc
  }),
  style: function(feature){
    const tipo = feature.get('tipo');
    if (tipo !== null){
      let featureStyle;
      switch(tipo.toUpperCase()){
        case 'UDOGRÁFICA':
          featureStyle = iconStyles.triangle;
          break;
        default:
          featureStyle = iconStyles.square
      }
      return featureStyle;
    }
  },
});



// const meteo = new VectorLayer({
//   source: new VectorSource({
//     format: new GeoJSON(),
//     url: function(extent){
//       return 'http://localhost:8080/geoserver/wfs?service=WFS&' +
//           'version=1.1.0&request=GetFeature&typename=csig:est_meteorologicas&' +
//           'cql_filter=estado=\'ATIVA\'&' +
//           'outputFormat=application/json&srsname=EPSG:3857';
//     },
//     projection: merc
//   })
// });


map.addLayer(osm);
// map.addLayer(stamen);
// map.addLayer(caop)
map.addLayer(meteo);
// map.addLayer(terrain);

const projection = merc;
const width = olExtent.getWidth(projection.getExtent()) / 256;
let resolutions = [];
let matrixIds = [];

for (let z = 0; z < 25; z++){
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions.push(width / Math.pow(2, z));
  matrixIds.push('EPSG:3857:' + z)
}

const dem = new TileLayer ({
  source: new WMTS({
    url: 'http://localhost:8080/geoserver/gwc/service/wmts',
    layer:'csig:dem_hillshade',
    projection: 'EPSG:3857',
    format: 'image/png',
    matrixSet: 'EPSG:3857',
    tileGrid: new WMTSTileGrid({
      origin:olExtent.getTopLeft(projection.getExtent()),
      resolutions: resolutions,
      matrixIds: matrixIds,
    })
  })
});

map.addLayer(dem)






